import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Skills from './components/Skills';
import Contact from './components/Contact';
// import Portfolio from './components/Projects';
import VantaNet from 'vanta/dist/vanta.net.min';
import Education from './components/Education';
import Workxp from './components/Workxp';
import Projects from './components/Projects';
// import Canvas from './components/Canvas';

const App = () => {

  // const [foo, setFoo] = useState({ foo: 'bar'});
  const [resumeData, setResumeData] = useState({});
  // const vantaRef = React.useRef();

  const skillRef = useRef();
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    console.log("aaaaaaaaaaaaaawidth === " + window.innerWidth);
    console.log("aaaaaaaaaaaaaaheight === " + window.innerHeight);
    if (!vantaEffect) {
      setVantaEffect(VantaNet({
        el: myRef.current,
        gyroControls: true,
        minHeight: window.innerHeight,
        minWidth: window.innerWidth,
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])


  const getResumeData = () => {
    $.ajax({
      url: './resumeData.json',
      dataType: 'json',
      cache: false,
      success: function (data) {
        setResumeData(data);
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      }
    });
  };

  useEffect(() => {
    getResumeData();
  });




  return (
    <div className="App">

      {/* <Header ref={vantaRef} data={resumeData.main} /> */}
      <div ref={myRef} id="vantaElement">
        <Header data={resumeData.main} />
      </div>
      <About data={resumeData.main} />
      <Education data={resumeData.resume} />
      <Workxp data={resumeData.resume} />
      <Skills ref={skillRef} data={resumeData.resume} />
      {/* <Canvas />  */}
      <Projects data={resumeData.portfolio} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>
  );

}

export default App;
